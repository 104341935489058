var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "alerts", fluid: "", tag: "section" } },
    [
      _c("base-v-component", {
        attrs: { heading: "Alerts", link: "components/alerts" }
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _c("base-subheading", {
                        attrs: { subheading: "Notification Style" }
                      }),
                      _c(
                        "base-material-alert",
                        { attrs: { color: "info", dark: "" } },
                        [
                          _vm._v(
                            "\n            This is a plain notification.\n          "
                          )
                        ]
                      ),
                      _c(
                        "base-material-alert",
                        { attrs: { color: "info", dark: "", dismissible: "" } },
                        [
                          _vm._v(
                            "\n            This is a notification with close button.\n          "
                          )
                        ]
                      ),
                      _c(
                        "base-material-alert",
                        {
                          attrs: {
                            color: "info",
                            dark: "",
                            dismissible: "",
                            icon: "mdi-bell"
                          }
                        },
                        [
                          _vm._v(
                            "\n            This is a notification with close button and icon and have many lines. You can see that the icon and the close button are always vertically aligned. This is a beautiful notification. So you don't have to worry about the style.\n          "
                          )
                        ]
                      ),
                      _c(
                        "base-material-alert",
                        {
                          attrs: {
                            color: "primary",
                            dark: "",
                            dismissible: "",
                            icon: "mdi-bell"
                          }
                        },
                        [
                          _vm._v(
                            "\n            You can see that the icon and the close button are always vertically aligned. This is a beautiful notification. So you don't have to worry about the style.\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _c("base-subheading", {
                        attrs: { subheading: "Notification states" }
                      }),
                      _vm._l(_vm.colors, function(color) {
                        return _c(
                          "base-material-alert",
                          {
                            key: color,
                            attrs: { color: color, dark: "", dismissible: "" }
                          },
                          [
                            _c("span", {
                              staticClass: "text-uppercase",
                              domProps: { textContent: _vm._s(color) }
                            }),
                            _vm._v(
                              ' — This is a regular alert made with the color of "' +
                                _vm._s(color) +
                                '"\n          '
                            )
                          ]
                        )
                      }),
                      _c(
                        "base-material-alert",
                        {
                          attrs: {
                            color: "secondary",
                            dark: "",
                            dismissible: ""
                          }
                        },
                        [
                          _c("span", [_vm._v("PRIMARY")]),
                          _vm._v(
                            ' — This is a regular alert made with the color "secondary"\n          '
                          )
                        ]
                      ),
                      _c(
                        "base-material-alert",
                        {
                          attrs: {
                            color: "pink darken-1",
                            dark: "",
                            dismissible: ""
                          }
                        },
                        [
                          _c("span", [_vm._v("PINK DARKEN-1")]),
                          _vm._v(
                            ' — This is a regular alert made with the color "pink darken-1"\n          '
                          )
                        ]
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    { staticClass: "text-center" },
                    [
                      _c("base-subheading", {
                        staticClass: "text-center",
                        attrs: { subheading: "Snackbar Locations" }
                      }),
                      _c(
                        "v-row",
                        { staticClass: "mt-n12", attrs: { justify: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "v-row",
                                _vm._l(_vm.directions, function(dir) {
                                  return _c(
                                    "v-col",
                                    { key: dir, attrs: { cols: "4" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "v-btn--block",
                                          attrs: {
                                            color: "secondary",
                                            default: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.randomColor(),
                                                (_vm.direction = dir),
                                                (_vm.snackbar = true)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(dir) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("base-subheading", {
                        staticClass: "text-center",
                        attrs: { subheading: "Dialogs" }
                      }),
                      _c(
                        "v-row",
                        { staticClass: "mt-n12", attrs: { justify: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "secondary",
                                            default: "",
                                            rounded: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.dialog = true
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    Classic Dialog\n                  "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "info",
                                            default: "",
                                            rounded: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.dialog2 = true
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    Notice Modal\n                  "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "pink darken-1",
                                            dark: "",
                                            default: "",
                                            rounded: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.dialog3 = true
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    Small Alert Modal\n                  "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-material-snackbar",
        _vm._b(
          {
            attrs: { type: _vm.color },
            model: {
              value: _vm.snackbar,
              callback: function($$v) {
                _vm.snackbar = $$v
              },
              expression: "snackbar"
            }
          },
          "base-material-snackbar",
          ((_obj = {}),
          (_obj[_vm.parsedDirection[0]] = true),
          (_obj[_vm.parsedDirection[1]] = true),
          _obj),
          false
        ),
        [
          _vm._v("\n    Welcome to "),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(" MATERIAL DASHBOARD PRO ")
          ]),
          _vm._v(" — a beautiful admin panel for every web developer.\n  ")
        ]
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "text-center" },
            [
              _c(
                "v-card-title",
                [
                  _vm._v("\n        Dialog Title\n\n        "),
                  _c("v-spacer"),
                  _c(
                    "v-icon",
                    {
                      attrs: { "aria-label": "Close" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v("\n          mdi-close\n        ")]
                  )
                ],
                1
              ),
              _c("v-card-text", [
                _vm._v(
                  "\n        Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth. Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.\n      "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Close\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.dialog2,
            callback: function($$v) {
              _vm.dialog2 = $$v
            },
            expression: "dialog2"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _vm._v(
                    "\n        How do you become an affiliate?\n\n        "
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-icon",
                    {
                      attrs: { "aria-label": "Close" },
                      on: {
                        click: function($event) {
                          _vm.dialog2 = false
                        }
                      }
                    },
                    [_vm._v("\n          mdi-close\n        ")]
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "body-1 text-center" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12", md: "8" } }, [
                        _c("div", [
                          _c("div", [_c("strong", [_vm._v("1. Register")])]),
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v(
                              "\n                The first step is to create an account at Creative Tim. You can choose a social network or go for the classic version, whatever works best for you.\n              "
                            )
                          ])
                        ])
                      ]),
                      _c(
                        "v-col",
                        {
                          staticClass: "hidden-sm-and-down",
                          attrs: { md: "4" }
                        },
                        [
                          _c(
                            "v-sheet",
                            [
                              _c("v-img", {
                                attrs: {
                                  src: "img/card-1.jpg",
                                  height: "100",
                                  width: "200"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "12", md: "8" } }, [
                        _c("div", [
                          _c("div", [_c("strong", [_vm._v("2. Apply")])]),
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v(
                              "\n                The first step is to create an account at "
                            ),
                            _c(
                              "a",
                              {
                                attrs: { href: "http://www.creative-tim.com/" }
                              },
                              [_vm._v("Creative Tim")]
                            ),
                            _vm._v(
                              ". You can choose a social network or go for the classic version, whatever works best for you.\n              "
                            )
                          ])
                        ])
                      ]),
                      _c(
                        "v-col",
                        {
                          staticClass: "hidden-sm-and-down",
                          attrs: { md: "4" }
                        },
                        [
                          _c(
                            "v-sheet",
                            [
                              _c("v-img", {
                                attrs: {
                                  src: "img/card-2.jpg",
                                  height: "100",
                                  width: "200"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _vm._v(
                          "\n            If you have more questions, don't hesitate to contact us or send us a tweet @creativetim. We're here to help!\n          "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-6",
                      attrs: {
                        color: "info",
                        depressed: "",
                        default: "",
                        rounded: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.dialog2 = false
                        }
                      }
                    },
                    [_vm._v("\n          Sounds good\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "300" },
          model: {
            value: _vm.dialog3,
            callback: function($$v) {
              _vm.dialog3 = $$v
            },
            expression: "dialog3"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _vm._v("\n        Are you sure?\n\n        "),
                  _c("v-spacer"),
                  _c(
                    "v-icon",
                    {
                      attrs: { "aria-label": "Close" },
                      on: {
                        click: function($event) {
                          _vm.dialog3 = false
                        }
                      }
                    },
                    [_vm._v("\n          mdi-close\n        ")]
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pb-6 pt-12 text-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog3 = false
                        }
                      }
                    },
                    [_vm._v("\n          Nevermind\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog3 = false
                        }
                      }
                    },
                    [_vm._v("\n          Yes\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }